<template>
    <div class="mx-auto">
        <div>
                <Loading :isLoading= "this.isLoading" />
                <div>
                    <form @submit="Generate" class="px-8 pt-6 pb-8 mb-4" style="">
                        <br>
                        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                            <div class="row">
                                <center>
                                    <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl title">
                                        Generar Layout</p>
                                </center>
                            </div>
                        </div>
                        <br>
                        <div class="grid grid-cols-1 sm:grid-cols-12 gap-5" style="">
                            <div class="col-span-6">
                                <label class="label-input" style="font-size:15px;color:black;color: #6E6E6E;">Fecha inicio:</label>
                                <!-- <input  type="text" id="Input_StartDate" class="shadow-md border-2 rounded-lg py-5 w-full px-5"
                                    v-on:change="calculateNumberEntryTwoDates()" minlength="2" maxlength="30" readonly="readonly" 
                                    pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="startDate" required> -->
    
                                <date-picker
                                    placeholder="Fecha inicio"
                                    locale="es,en"
                                    :editable="true"
                                    color="#810042"
                                    input-format="YYYY/MM/DD"
                                    @change="getDateStart"
                                    :localeConfig="{
                                            es: {
                                            dow: 6, //  first day of week
                                            dir: 'ltr',
                                            displayFormat: 'YYYY/MM/DD',
                                            lang: {
                                                    year: 'Año',
                                                    month: 'Mes',
                                                    dia: 'Día',
                                                    label: '',
                                                    submit: 'Seleccionar',
                                                    cancel: 'Cancelar',
                                                    now: 'Hoy',
                                                    nextMonth: 'ماه بعد',
                                                    prevMonth: 'ماه قبل',
                                                },
                                        },
                                        en: {
                                        dow: 0,
                                        dir: 'ltr',
                                        displayFormat: 'YYYY/MM/DD',
                                        lang: {
                                                label: '',
                                                submit: 'Select',
                                                cancel: 'Cancel',
                                                now: 'Now',
                                                nextMonth: 'Next month',
                                                prevMonth: 'Previous month',
                                            },
                                        },
                                    }" />
                            </div>
    
                            <div class="col-span-6">
                                <label class="label-input" style="font-size:15px;color:black;color: #6E6E6E;">Fecha final:</label>                 
                                    <!-- <input type="text"  id="Input_EndDate" class="shadow-md border-2 rounded-lg py-5 w-full px-5" v-on:change="calculateNumberEntryTwoDates()"
                                        minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="endDate" required> -->
    
                                    <date-picker 
                                        placeholder="Fecha final"
                                        locale="es,en"
                                        :editable="true"
                                        color="#810042"
                                        input-format="YYYY/MM/DD"
                                        :class="'bg-red-700'"
                                        @change="getDateEnd"
                                        :localeConfig="{
                                            es: {
                                            dow: 6, //  first day of week
                                            dir: 'ltr',
                                            displayFormat: 'YYYY/MM/DD',
                                            lang: {
                                                    year: 'Año',
                                                    month: 'Mes',
                                                    dia: 'Día',
                                                    label: '',
                                                    submit: 'Seleccionar',
                                                    cancel: 'Cancelar',
                                                    now: 'Hoy',
                                                    nextMonth: 'ماه بعد',
                                                    prevMonth: 'ماه قبل',
                                                },
                                        },
                                        en: {
                                        dow: 0,
                                        dir: 'ltr',
                                        displayFormat: 'YYYY/MM/DD',
                                        lang: {
                                                label: '',
                                                submit: 'Select',
                                                cancel: 'Cancel',
                                                now: 'Now',
                                                nextMonth: 'Next month',
                                                prevMonth: 'Previous month',
                                            },
                                        },
                                    }"
                                    />
                            </div>
                        </div>
    
                        <div class="mt-5" >
                            <label class="label-input-radio " style="font-size:15px;color:black;color: #6E6E6E;margin-left:1px;">Layout:</label>
                                <select lang="en" id="Select_TypeLayout" translate="no" class="shadow-md border-2 rounded-lg py-3 w-full px-5 " ref="stateSelected" required v-model="layout" 
                                    style="text-transform: uppercase;">
                                    <option value="alta_domicilio">Alta domicilio</option>
                                    <option value="alta_ingresos">Alta ingresos</option>
                                    <option value="alta_socios">Alta socios</option>
                                    <option value="alta_beneficiarios">Alta beneficiarios</option>
                                </select>
                        </div>
                        <br>
                        <div class="row">
                            <div class="">
                                <center>
                                    <button class="next_button" style="height:40px;width:240px; background-color:#fdce00" type="submit, text-decoration:none;border-color:#fdce00;background:#fdce00;">
                                        Generar
                                    </button>
                                </center>
                            </div>
                        </div>
                        
                    <br>
                    </form>
                    <br><br><br>
                </div>
            </div>
        <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
        <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
    </div>
    </template>
    
    
    
    <script scoped>
    import('@/assets/css/stylesheet.css')
    
    import MenuLateral from '@/components/LateralMenu.vue';
    import Loading from '@/components/Loading/VueLoading.vue'
    import moment from "moment";
    
    const layoutsType = {
        "alta_domicilio": "Alta_Domicilio_Fonsnte",
        "alta_ingresos": "Alta_Ingresos_Fonsnte",
        "alta_socios": "Alta_Socios_Fonsnte",
        "alta_beneficiarios": "Alta_Beneficiarios_Fonsnte"
    };
    
    export default {
        data() {
            return {
                startDate: "",
                endDate: "",
                maxDate:"",
                layout: "",
                columns: [],
                rows: [],
                isLoading: false,
                ccapi: process.env.VUE_APP_CCAPI,
                multiApi:process.env.VUE_APP_MULTIAPI,
            };
        },
        name: 'CambiarContraseña',
        props: {
            msg: String
        },
        components: {
            MenuLateral,
            Loading,
        },
        mounted() {
            
        },
        // async beforeMount() {
        //     await this.loadInfo();
        //     this.establisDateMaxToday();
        //     var self = this;
        //     console.log("max date: " + new Date(self.maxDate));
        //     $(function () {
        //         $.datepicker.setDefaults($.datepicker.regional["es"]);
        //         $("#Input_StartDate").datepicker({
        //             changeMonth: true,
        //             changeYear: true,
        //             yearRange: "1970:+nn",
        //             dateFormat: 'dd-mm-yy',
        //             maxDate: new Date(self.maxDate),
        //             monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        //             monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
        //             dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        //             dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
        //             dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
        //             onSelect:function(selectedDate, datePicker) {            
        //                 self.startDate = selectedDate;
        //             }
        //         });
        //     });
    
        //     $(function () {
        //         $.datepicker.setDefaults($.datepicker.regional["es"]);
        //         $("#Input_EndDate").datepicker({
        //             changeMonth: true,
        //             changeYear: true,
        //             yearRange: "1970:+nn",
        //             dateFormat: 'dd-mm-yy',
        //             maxDate: new Date(self.maxDate),
        //             monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        //             monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
        //             dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        //             dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
        //             dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
        //             onSelect:function(selectedDate, datePicker) {            
        //                 self.endDate = selectedDate;
        //             }
        //         });
        //     });
        // },
        methods:{ 
            async Generate(e){
                e.preventDefault();
                var swal = require('sweetalert2');
                this.isLoading = true;
    
                console.log(this.startDate,":::",this.endDate)
    
    
                var dateStart = new Date(this.startDate);
                var dateEnd = new Date(this.endDate);
                let list_Date_error = this.validateEmptyDate();
    
                if (list_Date_error.length > 0) {
                    swal.fire({
                        title: "Aviso",
                        html: `<p>Favor de seleccionar una fecha en: <br/>
                                <span style="font-weight: bold; color:red">${list_Date_error.join(", ")}</span>
                               </p>`,
                        icon: "warning",
                        confirmButtonColor: '#FEB72B',
                    });
    
                    this.isLoading = false
                    return;
                }
    
                if (dateStart.getTime() > dateEnd.getTime()) {
                    swal.fire({
                        title: "Aviso",
                        text: "La Fecha final no puede ser menor a la Fecha inicial",
                        icon: "warning",
                        confirmButtonColor: '#FEB72B',
                    });
                    this.isLoading = false
                    return
                }
    
                console.log(this.startDate,":::",this.endDate)
                await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/generate_csv/itbank`, {
                    params:{
                        token_auth: sessionStorage.getItem('login'),
                        type_layout: this.layout,
                        start_date: this.startDate,
                        end_date: this.endDate
                    }
                },
                {
                    'Content-Type': 'application/json'
                })
                .then(response => {         
                    this.isLoading = false
                    var base64 = response.data.response.document_info.doument_base64;
                    var csvString = atob(base64);
                    var universalBOM = "\uFEFF";
                    var a = window.document.createElement('a');
                    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
                    a.setAttribute('download', layoutsType[this.layout] + "_" + this.startDate.toString() +"_" + this.endDate.toString() + ".csv");
                    window.document.body.appendChild(a);
                    a.click();
    
                    swal.fire({
                        title: "Aviso",
                        text: "El archivo se generó correctamente",
                        icon: "success",
                        confirmButtonColor: '#FEB72B',
                    })
                }).catch(error => {
                    this.isLoading = false
                    swal.fire({
                        title: "Aviso",
                        text: "No hay información para mostrar",
                        icon: "info",
                        confirmButtonColor: '#FEB72B',
                    })
                });
            },
            // async loadInfo(){
            //     this.apiToken =  sessionStorage.getItem('login');
            // },
            // establisDateMaxToday(){
            //     var today = new Date();
            //     var dd = today.getDate();
            //     var mm = today.getMonth()+1; //January is 0!
            //     var yyyy = today.getFullYear();
            //     if(dd<10){
            //         dd='0'+dd
            //     } 
            //     if(mm<10){
            //         mm='0'+mm
            //     } 
    
            //     today = yyyy+'-'+mm+'-'+dd + 'T10:00:00.000';
                
            //     this.maxDate = today;
            //     // document.getElementById("startDate").setAttribute("max", today);
            //     // document.getElementById("endDate").setAttribute("max", today);
                
            // },
            // calculateNumberEntryTwoDates(){
            //     var dateToday = new Date()
            //     var dateAdminision = new Date(this.startDate)
    
            //     var antiguedadd = dateToday.getFullYear() - dateAdminision.getFullYear()
    
            //     dateAdminision.setFullYear(dateAdminision.getFullYear() + antiguedadd)
    
            //     if(dateAdminision > dateToday){
            //         antiguedadd--
            //     }
            //     return antiguedadd
            // },
            // orderDateByYearMonthDay(date, typeSplit){
            //     var elemtsOfDate = date.split(typeSplit)
            //     var day = elemtsOfDate[0]
            //     var month = elemtsOfDate[1]
            //     var year = elemtsOfDate[2]
            //     return year+"-"+month+"-"+day
            // },
            // orderDateByDayMonthYear(date, typeSplit){
            //     var elemtsOfDate = date.split(typeSplit)
            //     var year = elemtsOfDate[0]
            //     var month = elemtsOfDate[1]
            //     var day = elemtsOfDate[2]
            //     return day+"-"+month+"-"+year
            // },
            // formatDate(date) {
            //     console.log("fecha recibida: "+date)
            //     var d = new Date(date.toString())
            //     console.log(d)
            //     var month = '' + (d.getMonth() + 1)
            //     var day = '' + d.getDate()
            //     var year = d.getFullYear()
    
            //     if (month.length < 2) 
            //         month = '0' + month;
            //     if (day.length < 2) 
            //         day = '0' + day;
                
            //     console.log("retorna: "+[day, month, year].join('/'))
            //     return [day, month, year].join('/');
            // },
            validateEmptyDate(){
                let list_erros = [];
                if (this.startDate == "" || this.startDate == null) {
                    list_erros.push("Fecha de inicio");
                }
    
                if (this.endDate == "" || this.endDate == null) {
                    list_erros.push("Fecha final");
                }
                
                return list_erros;
            },
            getDateStart(event){
                this.startDate=moment(event._d).format("YYYY-MM-DD");
                console.log(this.startDate)
            },
            getDateEnd(event){
                this.endDate=moment(event._d).format("YYYY-MM-DD");
                console.log(this.endDate)
            },
        }
    }
    </script>
    
    <style>
    .vpd-input-group input{
      border: 2px solid rgba(205, 205, 205, 0.416) !important;
      border-start-end-radius: 10px;
      border-end-end-radius: 10px;
      padding: 5px !important;
      box-shadow: 0px 5px 10px 0px  rgba(205, 205, 205, 0.416) !important;
    }
    </style>
    
    <style scoped>
    #panel{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    select:focus-visible,
    input[type="text"]:focus-visible{
      border: 2px solid #810042 ;
      outline: none;
    }
    
    #form > div:nth-child(5) > div > div.row > div > div > div > div > span > span > label{
      background: #810042 !important;
    }
    </style>